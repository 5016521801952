import {Button, Col, Form, Layout, notification, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import {PageProps} from "../../app/types";
import queryString from "query-string";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setMenuActive, setPageTitle} from "../../../store/actions/app";
import {Link} from "react-router-dom";
import {OrderItemProps} from "../../order/type";
import {
  formatCurrency,
  formatDateTime,
  getFilter,
  getPage, isPermission
} from "../../../utils/helps";
import _ from "lodash";
import {api} from "../../../utils/apiServices";
import Search from "antd/es/input/Search";
import SJSelectCustomer from "../../../components/SJSelectCustomer";
import {permissions} from "../../../utils/permissions";

const {Content} = Layout;


export interface NewStateProps {
  order?: string | undefined,
  sort?: string | undefined,
  page?: string | undefined,
  username?: string | undefined,
  status?: string | undefined
}


export interface CustomerProps {
  email: string,
  firstName: string,
  lastName: string,
  mobile: string,
  username: string,
}

const ListDraftOrder: React.FC<PageProps> = (props) => {
  const [dataSource, setData] = useState([]);
  const [totalPage, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const search = queryString.parse(props?.location?.search || "");
  const defaultState = {
    order: "",
    sort: "",
    page: "",
    username: "",
    status: ""
  }
  const [state, setState] = useState({
    ...defaultState,
    ...search
  });
  const {order, sort, page, username} = state;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const newSearch = queryString.parse(props?.location?.search || "");
    dispatch(setPageTitle({title: "Danh sách đơn nháp"}));
    dispatch(setMenuActive({menu: "list_draft_order"}));
    document.title = "Danh sách đơn nháp";
    if(isPermission([permissions.DRAFT_ORDER_LIST_VIEW])){
      getData({...defaultState, ...newSearch})
    }
  }, [props.location]);
  const columns = [
    {
      title: 'Đơn hàng',
      dataIndex: 'code',
      key: 'code',
      render: (text: string) =>
        <Link
          to={"/draft-orders/" + text}>{text}</Link>
    },
    {
      title: 'Username (Tên khách hàng)',
      dataIndex: 'username',
      key: 'username',
      render: (text: string, record: OrderItemProps) =>
        <p>{record?.customer?.username} ({record?.customer?.firstName + " " + record?.customer?.lastName})</p>
    },
    {
      title: 'Ngày đặt đơn',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text: string) => <p>{formatDateTime(text)}</p>
    },
    {
      title: 'Giá trị đơn hàng',
      dataIndex: 'grandTotal',
      key: 'grandTotal',
      render: (text: string) => <p>{formatCurrency(text)}₫</p>
    },
    {
      title: 'Trạng thái đơn hàng',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => <p>{t("status:status." + text)}</p>
    }
  ];

  async function getData(newState?: NewStateProps) {
    setLoading(true);
    try {
      const params = _.pickBy({
        ...newState,
        sort: sort || "timestamp:DESC",
        page: newState ? parseInt(newState.page || "1") - 1 : 0,
      }, _.identity());
      const data = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/draft-orders", {
        params: {
          ...params,
          archived: false
        }
      });
      setData(data.data);
      setState({
        ...defaultState,
        ...params,
        page: getPage(params.page),
      });
      setTotal(data?.headers["x-total-count"]);
    } catch (e) {
      console.log("e", e)
      notification.error({message: "Lấy danh sách đơn nháp thất bại!"})
    }
    setLoading(false)
  }


  function onChangePage(page: number) {
    onSearch({...state, page: page.toString()})
  }


  function onChangeSearch(params: string, event: React.ChangeEvent<HTMLInputElement>) {
    let target = event.target;
    setState({
      ...state,
      [params]: ['checkbox', 'radio'].includes(target.type) ? target.checked : target.value
    })

  }

  function onPressEnter(params: string, event: React.KeyboardEvent<HTMLInputElement>) {
    if ('Enter' === event.key) { // Enter keyboard was pressed!
      onSearch({...state})
    }
  }

  function onSearch(newState?: NewStateProps) {
    let filter = _.pickBy({
      ...newState,
    }, _.identity());
    let pathname = props.location.pathname ? props.location.pathname : props.history.location.pathname;
    props.history.push(pathname + '?' + queryString.stringify(getFilter(filter)));
  }

  function onSelectCustomer(username: string) {
    setState({...state, username})
  }

  function onSelectOrderStatus(status: string) {
    setState({...state, status})
  }

  function onClearFilter() {
    const newState = {
      order: "",
      sort: "",
      page: "",
      username: ""
    };
    onSearch(newState)
  }

  function onPressSearch() {
    const newState = {
      ...state,
      page: "1"
    };
    // setState({...newState, page: "1"})
    onSearch({...newState})
  }

  return (<Content>
    <div className={'bg-color-white border-top-2x bd-color-pink pd10 mgbt20'}>
      <Form>
        <Row gutter={24}>
          <Col>
            <Form.Item label={"Mã đơn hàng"} labelCol={{span: 24}}>
              <Search value={order || ''}
                      placeholder={"Nhập mã đơn hàng"}
                      onPressEnter={onPressEnter.bind(this, 'order')}
                      onChange={onChangeSearch.bind(this, 'order')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={"Username / Tên KH"} labelCol={{span: 24}}>
              <SJSelectCustomer onChange={onSelectCustomer}
                                placeholder={"Chọn khách hàng"}
                                showSearch
                                value={username || ""}/>
            </Form.Item>
          </Col>
          {/*<Col>*/}
          {/*  <Form.Item label={"Trạng thái đơn hàng"} labelCol={{span: 24}}>*/}
          {/*    <SJSelectDraftOrderStatus onChange={onSelectOrderStatus}*/}
          {/*                              placeholder={"Chọn trạng thái đơn"}*/}
          {/*                              showSearch*/}
          {/*                              value={status || ""}/>*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
        </Row>
        <div
          className={"dpl-flex flex-direction-row justify-content-end mgbt20"}>

          <Button type="link" onClick={onClearFilter}>
            <span className={"txt-color-gray txt-size-h8"}>Xóa tìm kiếm</span>
          </Button>
          <Button type="primary"
                  className={"btn--blue pdl20 pdr20"}
                  onClick={onPressSearch}>
            Tìm kiếm
          </Button>

        </div>
      </Form>
    </div>
    <div>
      <Table dataSource={dataSource} columns={columns}
             loading={loading}
             rowKey={(record) => record?.code || ""}
             locale={{
               emptyText: "Không có dữ liệu"
             }}
             pagination={{
               showSizeChanger: false,
               pageSize: 25,
               total: totalPage,
               onChange: onChangePage,
               current: parseInt(page.toString()) || 1
             }}/>
    </div>
  </Content>)
};

export default ListDraftOrder
