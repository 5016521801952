// eslint-disable-next-line react-hooks/exhaustive-deps
import React, {useEffect, useState} from "react";
import {Button, Layout, Modal, notification, Table, Timeline} from "antd";
import {api} from "../../../utils/apiServices";
import {PageProps} from "../../app/types";
import {OrderItemProps, ProductItemProps} from "../type";
import {Link} from "react-router-dom";
import {
  formatCurrency,
  formatDateTime,
  getFilter,
  isPermission
} from "../../../utils/helps";
import queryString from "query-string";
import {setPageTitle} from "../../../store/actions/app";
import {useDispatch} from "react-redux";
import {OrderStatus} from "../../../utils/orderStatus";
import SJSelectOrderStatus from "../../../components/SJSelectOrderStatus";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {
  CloseOutlined,
  EditOutlined,
  UnorderedListOutlined
} from "@ant-design/icons/lib";
import {permissions} from "../../../utils/permissions";

const {confirm} = Modal;
const {Content} = Layout;
const defaultCustomer = {
  email: "",
  firstName: "",
  lastName: "",
  mobile: "",
  username: "",

}
const defaultLocation = {
  display: ""
};
const defaultShippingAddress = {
  detail: "",
  fullName: "",
  location: defaultLocation,
  phone: "",
  order: "",
  note: ""
};

const defaultOrder = {
  code: "",
  customer: defaultCustomer,
  shippingAddress: defaultShippingAddress,
  orderDate: "",
  recipientAddress: "",
  status: "",
  totalFee: "",
  totalPaid: "",
};

export interface OrderSkuItemProps {
  code?: string,
  name?: string,
  price?: number,
  primaryImage?: string,
  description?: string,
  product: OrderProductItemProps
}

export interface OrderProductItemProps {
  id?: string,
  name?: string,
  price?: number,
  primaryImage?: string,
  description?: string,
}

export interface milestonesProps {
  timestamp: string,
  status: string
}

const DetailOrder: React.FC<PageProps> = (props) => {
  const dispatch = useDispatch();
  const search = queryString.parse(props?.location?.search || "");
  const [order, setDetailOrder] = useState<OrderItemProps>(defaultOrder);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(search.page ? search.page.toString() : "");
  const [totalPage, setTotalPage] = useState(0);
  const [orderPackage, setPackage] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [visible, setVisibleModal] = useState(false);
  const [visibleMilestones, showVisibleMilestones] = useState(false);
  const [imgShow, setImgShow] = useState("");
  const [loadingAction, setLoadingAction] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const {t} = useTranslation();
  useEffect(() => {
    if(isPermission([permissions.ORDER_DETAIL_VIEW])){
      getDetail()
      }
    }, []
  )
  useEffect(() => {
    dispatch(setPageTitle({title: "Chi tiết đơn hàng"}));
    getPackages();
    const {code} = props?.match?.params;
    document.title = "Đơn hàng - " + code;
  }, [page])

  async function getDetail() {
    const {code} = props?.match?.params;
    setLoading(true);
    try {
      const orderInfo = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/orders/" + code);
      setDetailOrder(orderInfo.data);
    } catch (e) {
      notification.error({message: "Lấy chi tiết đơn thất bại!"})
      console.log("e", e)
    }
    setLoading(false)
  }

  async function getPackages() {
    try {
      const {code} = props?.match?.params;
      const params = {
        page: page ? parseInt(page) - 1 : 0,
        sort: "createdAt"
      };
      const orderPackages = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/orders/" + code + "/items", {params});
      setPackage(orderPackages.data);
      setTotalPage(orderPackages.headers["x-total-count"])
    } catch (e) {
      notification.error({message: "Lấy danh sách kiện thất bại!"})
    }
  }

  function onChangePage(page: number) {
    const newPage = page.toString();
    setPage(newPage)
    let pathname = props.location.pathname ? props.location.pathname : props.history.location.pathname;
    props.history.push(pathname + '?' + queryString.stringify(getFilter({page})));
  }

  function showImage(url: string) {
    if (url.length > 0) {
      setImgShow(url);
      setVisibleModal(true)
    } else {
      setTimeout(() => {
        setImgShow("");
      }, 500);
      setVisibleModal(false)
    }
  }

  const orderConfirmation = async () => {
    setLoadingAction(true)
    try {
      const {code} = props?.match?.params;
      await api.patch(process.env.REACT_APP_NAME_SPACE_ORDER + "/orders/" + code);
      notification.success({message: "Xác nhận thành công!"});
      await getDetail();
      setEditStatus(false);
    } catch (e) {
      notification.error({message: "Xác nhận thất bại!"});
      console.log("e", e?.response)
    }
    setLoadingAction(false)
  };
  const onChangeOrderStatus = (code: string) => {
    confirm({
      title: 'Cảnh báo',
      icon: <ExclamationCircleOutlined/>,
      content: 'Bạn có chắc chắn muốn chuyển trạng thái của đơn?',
      onOk() {
        changeOrderStatus(code)
      },
      onCancel() {
        setEditStatus(false);
        console.log('Cancel');
      },
    });
  };
  const changeOrderStatus = async (status: string) => {
    setLoading(true);
    const {code} = props?.match?.params;
    try {
      await api.patch(process.env.REACT_APP_NAME_SPACE_ORDER + "/orders/" + code, {status});
      notification.success({message: "Chuyển trạng thái đơn thành công"});
      setEditStatus(false);
      setDetailOrder({...order, status})
    } catch (e) {
      notification.error({message: "Chuyển trạng thái đơn thất bại"});
      setEditStatus(false);
    }
    setLoading(false);
  };

  const showHistoryStatus = async () => {
    setLoading(true);
    const {code} = props?.match?.params;
    try {
      const data = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/orders/" + code + "/milestones");
      setMilestones(data.data);
      showVisibleMilestones(true)
    } catch (e) {
      notification.error({message: "Lấy lịch sử thay đổi trạng thái đơn thất bại!"});
    }
    setLoading(false);
  };

  const columns = [
    {
      title: 'Ảnh sản phẩm',
      dataIndex: 'variantImage',
      key: 'variantImage',
      width: "5%",
      render: (text: string, record: ProductItemProps) => <img
        src={text || record?.variants?.product?.primaryImage || "/static/images/img-nf.png"}
        alt={"image"}
        onClick={() => showImage(text || record?.variants?.product?.primaryImage || "/static/images/img-nf.png")}
        style={{width: 40, height: 40}}/>
    },
    {
      title: 'Tên sản phẩm',
      key: 'productName',
      dataIndex: 'productName',
      width: "40%",
      render: (text: string, record: ProductItemProps) =>
        <a href={record.url || "/"} target={record.url ? "_blank" : "_self"} rel="noopener noreferrer">
          <div className={"txt-two-line"}>{record?.productName || ""}</div>
        </a>
    },
    {
      title: 'Thuộc tính sản phẩm',
      key: 'name',
      dataIndex: 'name',
      width: "40%",
      render: (text: string, record: ProductItemProps) =>
        <p className={"txt-two-line"}>{text || ""}</p>
    },
    {
      title: 'Giá sản phẩm',
      dataIndex: 'price',
      key: 'price',
      width: "20%",
      render: (text: string) =>
        <p>{formatCurrency(text)}₫</p>
    },
    {
      title: 'Số lượng mua',
      dataIndex: 'quantity',
      key: 'quantity',
      width: "20%",
      render: (text: string) => <p>{formatCurrency(text, ".")}</p>
    },
  ];
  const milestonesTimeline = milestones.map((mile: milestonesProps, index) =>
    <Timeline.Item
      color="green"
      key={index}><span><span
      className={"txt-color-gray"}>{t("status:status." + mile.status)}: </span> {formatDateTime(mile.timestamp)}</span></Timeline.Item>
  );

  return (
    <Content>
      <div style={{backgroundColor: "#fff"}}>
        <Layout>
          <div className={"border-bottom-2x bd-color-pink"}>
            <h3 className={'mgbt10'}>Thông tin khách hàng</h3>
          </div>
          <div className={"dpl-flex flex-direction-row pd20 bg-color-white"}>
            <div className={"pdr40 border-right-1x bd-color-gray"}>
              <div className={"robotomedium"}><span className={"robotoregular"}>Mã đơn hàng:</span> {order.code}
              </div>
              <div className={"pdt10"}>
                <span>Trạng thái : {editStatus ? <CloseOutlined
                    onClick={() => setEditStatus(false)}
                    style={{color: "blue"}}/> :
                  isPermission([permissions.ORDER_UPDATE_CHANGE_STATUS]) ? <EditOutlined style={{color: "blue", alignSelf: "center"}}
                                onClick={() => setEditStatus(true)}/> : <></>
                }</span>
                <div className={"dpl-flex flex-direction-row"}>
                  <SJSelectOrderStatus value={order.status || ""}
                                       showText={!editStatus}
                                       onChange={onChangeOrderStatus}/>
                  {
                    !editStatus && <UnorderedListOutlined
                        onClick={showHistoryStatus}
                        style={{
                          color: "blue",
                          alignSelf: "center",
                          marginLeft: 10
                        }}/>
                  }
                </div>
              </div>
            </div>
            <div className={"pdl20"}>
              <div className={"robotomedium"}><span
                className={"robotoregular"}>Tên khách hàng:</span> {order?.shippingAddress?.fullName || "--"}
              </div>
              <div className={"pdt10 robotomedium"}><span
                className={"robotoregular"}>Số điện thoại:</span> {order?.shippingAddress?.phone || "--"}
              </div>
              <div className={"pdt10 robotomedium"}><span
                className={"robotoregular"}>Địa chỉ nhận hàng: </span>{order?.shippingAddress?.detail ? order?.shippingAddress?.detail + (order.shippingAddress?.location?.display ? (", " + order.shippingAddress?.location?.display) : "") : "--"}
              </div>
              <div className={"pdt10 robotomedium"}><span
                className={"robotoregular"}>Ghi chú: </span>{order?.shippingAddress?.note || "--"}
              </div>
            </div>
          </div>
        </Layout>
        <Layout>
          <div
            className={"mgt30 mgbt20 dpl-flex flex-direction-row justify-content-between"}>
            <h3 className={'mgbt10'}>Danh sách sản phẩm</h3>
            {order?.status === OrderStatus.PENDING && isPermission([permissions.ORDER_UPDATE_CONFIRM]) &&
            <Button type={"primary"} className={"btn--blue pdl20 pdr20"}
                    disabled={loadingAction || loading}
                    loading={loadingAction || loading}
                    onClick={orderConfirmation}>Xác
                nhận đơn hàng</Button>}

          </div>
          <div className={"bg-color-white"}>
            <Table dataSource={orderPackage} columns={columns}
                   loading={loading}
                   rowKey={(record) => record?.code || ""}
                   bordered
                   locale={{
                     emptyText: "Không có dữ liệu"
                   }}
                   pagination={{
                     showSizeChanger: false,
                     pageSize: 25,
                     total: totalPage,
                     onChange: onChangePage,
                     current: parseInt(page.toString()) || 1,

                   }}/>
          </div>
        </Layout>
      </div>
      <Modal
        visible={visible}
        closable={false}
        footer={null}
        onCancel={() => showImage("")}
      >
        <img src={imgShow} alt={""}
             style={{width: "100%", height: "100%"}}/>
      </Modal>
      <Modal visible={visibleMilestones}
             closable={false}
             footer={null}
             onCancel={() => showVisibleMilestones(false)}>
        <Timeline>
          {milestonesTimeline}
        </Timeline>
      </Modal>
    </Content>
  )
};
export default DetailOrder;
