import React, {useState} from "react";
import {Popover} from "antd";
import {useTranslation} from "react-i18next";

const languages = ['en', 'vi'];

const SJLanguage: React.FC<{}> = () => {
  const {i18n} = useTranslation();
  const [data, setData] = useState(languages.filter(lang => lang !== i18n.language));
  // useEffect(() => {
  //   localStorage.getItem()
  // })
  async function onChangeLanguage(language: string) {
    await i18n.changeLanguage(language);
    setData(languages.filter(lang => lang !== language))
  }

  const content = (
    <div>
      {data.map(lang => (
        <img src={"/static/images/flags/" + lang + ".png"}
             style={{width: 30, height: 30}}
             key={lang}
             onClick={() => onChangeLanguage(lang)}
             alt={"flag"}/>)
      )}
    </div>
  );
  return <Popover content={content}>
    <img src={"/static/images/flags/" + i18n.language + ".png"}
         style={{width: 30, height: 30}}
         alt={"flag"}/>
  </Popover>
};
export default SJLanguage
