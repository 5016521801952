import React, {useEffect, useState} from "react";
import {notification, Select, Spin} from "antd";
import {api} from "../../utils/apiServices";
import _ from "lodash";
import lodash from "lodash";

const {Option} = Select;


export interface SJSelectOrderStatusProps {
  value: string,
  onChange: (text: string) => void,
  showSearch?: boolean,
  placeholder?: string,
  showText?: boolean
}

export interface OrderStatusProps {
  code: string,
  name: string,
}

const SJSelectOrderStatus: React.FC<SJSelectOrderStatusProps> = ({value, onChange, placeholder, showText}) => {
  const [status, setStatus] = useState<OrderStatusProps[]>([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    getStatus(value || "")
  }, []);

  async function getStatus(status: string) {
    setFetching(true);
    try {
      const params = _.pickBy({
        status,
        size: 1000
      }, _.identity());
      const data = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/order-status", {params});
      setStatus(data.data);
    } catch (e) {
      notification.error({message: "Lấy danh sách trạng thái đơn thất bại!"});
      console.log("err", e)
    }
    setFetching(false)
  }

  const newValue = (fetching || value === "") ? undefined : value;
  const labelStatus = fetching ? "--" : lodash.find(status, st => st.code === value)?.name;
  return showText ? <span>{labelStatus}</span> :
    <Select
      value={newValue}
      placeholder={placeholder || "Chọn trạng thái đơn"}
      notFoundContent={fetching ? <Spin size="small"/> : null}
      onChange={onChange}
      style={{width: '100%'}}
    >
      {status.map((st) => (
        <Option key={st.code}
                value={st.code}>{st.name}</Option>
      ))}
    </Select>
};
export default SJSelectOrderStatus
