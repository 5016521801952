import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import BasicLayout from '../layouts/BasicLayout';
import React from 'react';
import {routes} from './data';
import {formatRoutes} from "../utils/helps";

const newRoutes = formatRoutes(routes) || [];
export const Routes: React.FC<{}> = () => (
  <Router>
    <Switch>
      {newRoutes.map((route, i) => (
        RouteWithSubRoutes(route, i)
      ))}
    </Switch>
  </Router>
);

function RouteWithSubRoutes(route: any, index: number) {
  return (
    <Route
      key={index}
      path={route.path}
      exact
      render={(props) => (
        <BasicLayout authorized={!route.notAuthorized} permissions={route.permissions}>
          <route.component exact {...props} routes={route.routes}/>
        </BasicLayout>
      )}
    />
  );
};
