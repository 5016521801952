export const OrderStatus = {
  NEW: "NEW",
  DEPOSITED: "DEPOSITED",
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  PURCHASING: "PURCHASING",
  ORDERED: "ORDERED",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  PURCHASED: "PURCHASED",
  MERCHANT_DELIVERING: "MERCHANT_DELIVERING",
  PUTAWAY: "PUTAWAY",
  TRANSPORTING: "TRANSPORTING",
  READY_FOR_DELIVERY: "READY_FOR_DELIVERY",
  DELIVERING: "DELIVERING",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
  OUT_OF_STOCK: "OUT_OF_STOCK",
  MIA: "MIA",
  DELIVERY_CANCELLED: "DELIVERY_CANCELLED"
};
