import {AppActionTypes} from "../actions/app/type";
import {APP_CONSTANTS} from "../constants";

interface AppInterface {
  title: string,
  menu: string
}

const initialState: AppInterface = {
  title: "Gobiz Shinjin",
  menu: ""
};

export const app = (state = initialState, action: AppActionTypes) => {
  switch (action.type) {
    case APP_CONSTANTS.SET_PAGE_TITLE :
    case APP_CONSTANTS.SET_MENU:
      return {...state, ...{...action.payload}};
    default :
      return state
  }
};
