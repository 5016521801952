import React, {useEffect} from "react";
import {PageProps} from "../../app/types";
import queryString from "query-string";
import Cookies from "js-cookie";
import {api} from "../../../utils/apiServices";
import {notification} from "antd";

const LoginCallBack: React.FC<PageProps> = ({history, location}) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PRODUCT_NAME || "Auth";
    const hash = queryString.parse(location?.hash || "");
    const access_token = (hash?.access_token || "").toString();
    const expire_at = (hash?.expire_at || "").toString();
    Cookies.set('access_token', access_token, {expires: new Date(Date.parse(expire_at))});
    getUser(expire_at)
  }, []);

  const getUser = async (expire_at:string) => {
   try {
     const data = await api.get("/profile");
     Cookies.set("profile", JSON.stringify(data.data),{expires: new Date(Date.parse(expire_at))})
     history.replace("/")
   }catch (e) {
     console.log("eeee",e)
      // notification.error({})
   }
  }
  return <></>
};
export default LoginCallBack
