import React, {useEffect, useState} from "react";
import {Button, Input, Layout, Modal, notification, Table} from "antd";
import {api} from "../../../utils/apiServices";
import {PageProps} from "../../app/types";
import {OrderItemProps, ProductItemProps} from "../type";
import {formatCurrency, getFilter, isPermission} from "../../../utils/helps";
import queryString from "query-string";
import {setPageTitle} from "../../../store/actions/app";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {permissions} from "../../../utils/permissions";

const {TextArea} = Input;
const {Content} = Layout;
const defaultCustomer = {
  email: "",
  firstName: "",
  lastName: "",
  mobile: "",
  username: "",

};
const defaultLocation = {
  display: ""
};
const defaultShippingAddress = {
  detail: "",
  fullName: "",
  location: defaultLocation,
  phone: "",
  order: "",
  note: ""
};

const defaultOrder = {
  code: "",
  customer: defaultCustomer,
  shippingAddress: defaultShippingAddress,
  orderDate: "",
  recipientAddress: "",
  status: "",
  totalFee: "",
  totalPaid: "",
}

export interface OrderSkuItemProps {
  code?: string,
  name?: string,
  price?: number,
  primaryImage?: string,
  description?: string,
  product: OrderProductItemProps
}

export interface OrderProductItemProps {
  id?: string,
  name?: string,
  price?: number,
  primaryImage?: string,
  description?: string,
}

const DetailDraftOrder: React.FC<PageProps> = (props) => {
  const search = queryString.parse(props?.location?.search || "");
  const [order, setDetailOrder] = useState<OrderItemProps>(defaultOrder);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(search.page ? search.page.toString() : "");
  const [totalPage, setTotalPage] = useState(0);
  const [orderPackage, setPackage] = useState([]);
  const [visible, setVisibleModal] = useState(false);
  const [visiblePaymentConfirmation, setVisiblePaymentConfirmation] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [note, setNote] = useState("");
  const [imgShow, setImgShow] = useState("");
  const dispatch = useDispatch();
  // const {t} = useTranslation();
  useEffect(() => {
    getDetail()
  }, []);
  useEffect(() => {
    dispatch(setPageTitle({title: "Chi tiết đơn nháp"}));
    getPackages();
    const {code} = props?.match?.params;
    document.title = "Đơn nháp - " + code;
  }, [page]);

  async function getDetail() {
    const {code} = props?.match?.params;
    setLoading(true);
    try {
      const orderInfo = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/draft-orders/" + code);
      setDetailOrder(orderInfo.data);
    } catch (e) {
      notification.error({message: "Lấy chi tiết đơn nháp thất bại"})
    }
    setLoading(false)
  }

  async function getPackages() {
    try {
      const {code} = props?.match?.params;
      const params = {
        page: page ? parseInt(page) - 1 : 0,
        sort: "createdAt:DESC"
      };
      const orderPackages = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/draft-orders/" + code + "/items", {params});
      setPackage(orderPackages.data)
      setTotalPage(orderPackages.headers["x-total-count"])
    } catch (e) {
      notification.error({message: "Lấy danh sách kiện thất bại"})
    }
  }

  function onChangePage(page: number) {
    const newPage = page.toString();
    setPage(newPage);
    let pathname = props.location.pathname ? props.location.pathname : props.history.location.pathname;
    props.history.push(pathname + '?' + queryString.stringify(getFilter({page})));
  }

  const columns = [
    {
      title: 'Ảnh sản phẩm',
      dataIndex: 'variantImage',
      key: 'variantImage',
      width: "5%",
      render: (text: string, record: ProductItemProps) => <img
        src={text || record?.variants?.product?.primaryImage || ""}
        alt={"image"}
        onClick={() => showImage(text || record?.variants?.product?.primaryImage || "")}
        style={{width: 40, height: 40}}/>
    },
    {
      title: 'Tên sản phẩm',
      key: 'productName',
      width: "40%",
      render: (text: string, record: ProductItemProps) =>
        <a
          href={record.url || "/"} target={record.url ? "_blank" : "_self"} rel="noopener noreferrer">
          <div className={"txt-two-line"}>{record?.productName || ""}</div>
        </a>
    },
    {
      title: 'Thuộc tính sản phẩm',
      key: 'name',
      width: "40%",
      dataIndex: 'name',
      render: (text: string, record: ProductItemProps) =>
        <p className={"txt-two-line"}>{text || ""}</p>
    },
    {
      title: 'Giá sản phẩm',
      dataIndex: 'price',
      key: 'price',
      width: "20%",
      render: (text: string) =>
        <p>{formatCurrency(text)}₫</p>
    },
    {
      title: 'Số lượng mua',
      dataIndex: 'quantity',
      key: 'quantity',
      width: "20%",
      render: (text: string) => <p>{formatCurrency(text, ".")}</p>
    },
  ];

  function showImage(url: string) {
    if (url.length > 0) {
      setImgShow(url);
      setVisibleModal(true)
    } else {
      setTimeout(() => {
        setImgShow("");
      }, 500);
      setVisibleModal(false)
    }
  }

  const onSubmit = async () => {
    setConfirmLoading(true);
    try {
      const data = await api.post(process.env.REACT_APP_NAME_SPACE_ORDER + "/orders", {
        draftOrderCode: order.code,
        note
      });
      setVisiblePaymentConfirmation(false);
      setNote("");
      props.history.push("/orders");
      notification.success({message: "Xác nhận thanh toán thành công!"})
    } catch (e) {
      console.log("e?.response?", e?.response)
      if (e?.response?.data?.title === "billing_address_not_exist") {
        notification.error({message: "Đơn chưa nhập địa chỉ!"})
      } else {
        notification.error({message: "Xác nhận thanh toán thất bại!"})

      }
    }
    setConfirmLoading(false);
  };
  const onCancel = () => {
    setVisiblePaymentConfirmation(false);
    setNote("")
  };

  const onChangeNote = (e: { target: { value: string } }) => {
    setNote(e.target.value)
  }


  return (
    <Content>
      <div style={{backgroundColor: "#fff"}}>
        <Layout>
          <div className={"border-bottom-2x bd-color-pink"}>
            <h3 className={'mgbt10'}>Thông tin khách hàng</h3>
          </div>
          <div className={"dpl-flex flex-direction-row pd20 bg-color-white"}>
            <div className={"pdr40 border-right-1x bd-color-gray"}>
              <div className={"robotomedium"}><span className={"robotoregular"}>Mã đơn hàng:</span> {order.code}
              </div>
            </div>
            <div className={"pdl20"}>
              <div className={"robotomedium"}><span
                className={"robotoregular"}>Tên khách hàng:</span> {order?.shippingAddress?.fullName || "--"}
              </div>
              <div className={"pdt10 robotomedium"}><span
                className={"robotoregular"}>Số điện thoại:</span> {order?.shippingAddress?.phone || "--"}
              </div>
              <div className={"pdt10 robotomedium"}><span
                className={"robotoregular"}>Địa chỉ nhận hàng: </span>{order?.shippingAddress?.detail ? order?.shippingAddress?.detail + ", " + order.shippingAddress?.location?.display : "--"}
              </div>
              <div className={"pdt10 robotomedium"}><span
                className={"robotoregular"}>Ghi chú: </span>{order?.shippingAddress?.note || "--"}
              </div>
            </div>
          </div>
        </Layout>
        <Layout>
          <div
            className={"mgt30 mgbt20 dpl-flex flex-direction-row justify-content-between"}>
            <h3 className={'mgbt10'}>Danh sách sản phẩm</h3>
            {
              isPermission([permissions.DRAFT_ORDER_UPDATE_CONFIRM_PAYMENT]) &&
              <Button type={"primary"} className={"btn--blue pdl20 pdr20"}
                      disabled={confirmLoading || loading}
                      loading={confirmLoading || loading}
                      onClick={() => setVisiblePaymentConfirmation(true)}>Xác
                nhận thanh toán</Button>
            }
          </div>
          <div className={"bg-color-white"}>
            <Table dataSource={orderPackage} columns={columns}
                   loading={loading}
                   rowKey={(record) => record?.code || ""}
                   bordered
                   locale={{
                     emptyText: "Không có dữ liệu"
                   }}
                   pagination={{
                     showSizeChanger: false,
                     pageSize: 25,
                     total: totalPage,
                     onChange: onChangePage,
                     current: parseInt(page.toString()) || 1,

                   }}/>
          </div>
        </Layout>
      </div>
      <Modal
        visible={visible}
        closable={false}
        footer={null}
        onCancel={() => showImage("")}
      >
        <img src={imgShow} alt={""}
             style={{width: "100%", height: "100%"}}/>
      </Modal>
      <Modal
        title="Xác nhận thanh toán"
        visible={visiblePaymentConfirmation}
        okText={"Xác nhận"}
        okButtonProps={{className: "btn--blue"}}
        cancelText={"Hủy bỏ"}
        onOk={onSubmit}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
      >
        <TextArea
          value={note}
          onChange={onChangeNote}
          placeholder="Nhập ghi chú!"
          autoSize={{minRows: 3, maxRows: 5}}
        />
      </Modal>
    </Content>
  )
};
export default DetailDraftOrder;
