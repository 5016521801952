import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setMenuActive, setPageTitle} from "../../../store/actions/app";
import {Button, Col, Form, Layout, Result, Row, Table} from "antd";
import Search from "antd/es/input/Search";
import {api} from "../../../utils/apiServices";
import {
  formatCurrency,
  formatDateTime,
  getFilter,
  getPage, isPermission
} from "../../../utils/helps";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import SJSelectCustomer from "../../../components/SJSelectCustomer";
import _ from "lodash";
import queryString from "query-string";
import {PageProps} from "../../app/types";
import {OrderItemProps} from "../type";
import SJSelectOrderStatus from "../../../components/SJSelectOrderStatus";
import {permissions} from "../../../utils/permissions";

const {Content} = Layout;

export interface NewStateProps {
  order?: string | undefined,
  sort?: string | undefined,
  page?: string | undefined,
  username?: string | undefined,
  status?: string | undefined
}

export interface CustomerProps {
  email: string,
  firstName: string,
  lastName: string,
  mobile: string,
  username: string,
}

const ListOrder: React.FC<PageProps> = (props) => {
  const [dataSource, setData] = useState([]);
  const [totalPage, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const search = queryString.parse(props?.location?.search || "");
  const defaultState = {
    order: "",
    sort: "",
    page: "",
    username: "",
    status: "",
  }
  const [state, setState] = useState({
    ...defaultState,
    ...search
  });
  const {order, sort, page, username, status} = state;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle({title: "Danh sách đơn hàng"}));
    dispatch(setMenuActive({menu: "list_order"}));
    document.title = "Danh sách đơn hàng";
    const newSearch = queryString.parse(props?.location?.search || "");
    if(isPermission([permissions.ORDER_LIST_VIEW])){
      getData({...defaultState, ...newSearch})
    }
  }, [props.location])
  const columns = [
    {
      title: 'Đơn hàng',
      dataIndex: 'code',
      key: 'code',
      render: (text: string) =>
        <Link
          to={"/orders/" + text}>{text}</Link>
    },
    {
      title: 'Username (Tên khách hàng)',
      dataIndex: 'username',
      key: 'username',
      render: (text: string, record: OrderItemProps) =>
        <p>{record?.customer?.username} ({record?.customer?.firstName + " " + record?.customer?.lastName})</p>
    },
    {
      title: 'Ngày đặt đơn',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text: string) => <p>{formatDateTime(text)}</p>
    },
    {
      title: 'Giá trị đơn hàng',
      dataIndex: 'totalPaid',
      key: 'totalPaid',
      render: (text: string) => <p>{formatCurrency(text)}₫</p>
    },
    {
      title: 'Trạng thái đơn hàng',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => <p>{t("status:status." + text)}</p>
    }
  ];

  async function getData(newState?: NewStateProps) {
    setLoading(true);
    try {
      const params = _.pickBy({
        ...newState,
        sort: sort || "timestamp:DESC",
        page: newState ? parseInt(newState.page || "1") - 1 : 0,
      }, _.identity());
      const data = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/orders", {params});
      setData(data.data);
      setState({
        ...defaultState,
        ...params,
        page: getPage(params.page),
      });

      setTotal(data?.headers["x-total-count"]);
    } catch (e) {
      console.log("e", e)
    }
    setLoading(false)
  }


  function onChangePage(page: number) {
    onSearch({...state, page: page.toString()})
  }


  function onChangeSearch(params: string, event: React.ChangeEvent<HTMLInputElement>) {
    let target = event.target;
    setState({
      ...state,
      [params]: ['checkbox', 'radio'].includes(target.type) ? target.checked : target.value
    })

  }

  function onPressEnter(params: string, event: React.KeyboardEvent<HTMLInputElement>) {
    if ('Enter' === event.key) { // Enter keyboard was pressed!
      onSearch({...state})
    }
  }

  function onSearch(newState?: NewStateProps) {
    let filter = _.pickBy({
      ...newState,
    }, _.identity());
    let pathname = props.location.pathname ? props.location.pathname : props.history.location.pathname;
    props.history.push(pathname + '?' + queryString.stringify(getFilter(filter)));
  }

  function onSelectCustomer(username: string) {
    setState({...state, username})
  }

  function onSelectOrderStatus(status: string) {
    setState({...state, status})
  }

  function onClearFilter() {
    const newState = {
      order: "",
      sort: "",
      page: "",
      username: "",
      status: ""
    };
    onSearch(newState)
  }

  function onPressSearch() {
    const newState = {
      ...state,
      page: "1"
    };
    // setState({...newState, page: "1"})
    onSearch({...newState})
  }
  if(!isPermission([permissions.ORDER_LIST_VIEW])){
    return <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={<Button type="primary">Back Home</Button>}/>}
  return (<Content>
    <div className={'bg-color-white border-top-2x bd-color-pink pd10 mgbt20'}>
      <Form>
        <Row gutter={24}>
          <Col>
            <Form.Item label={"Mã đơn hàng"} labelCol={{span: 24}}>
              <Search value={order || ''}
                      placeholder={"Nhập mã đơn hàng"}
                      onPressEnter={onPressEnter.bind(this, 'order')}
                      onChange={onChangeSearch.bind(this, 'order')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={"Username / Tên KH"} labelCol={{span: 24}}>
              <SJSelectCustomer onChange={onSelectCustomer}
                                placeholder={"Chọn khách hàng"}
                                showSearch
                                value={username || ""}/>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={"Trạng thái đơn hàng"} labelCol={{span: 24}}>
              <SJSelectOrderStatus onChange={onSelectOrderStatus}
                                   placeholder={"Chọn trạng thái đơn"}
                                   value={status || ""}/>
            </Form.Item>
          </Col>
        </Row>
        <div
          className={"dpl-flex flex-direction-row justify-content-end mgbt20"}>

          <Button type="link" onClick={onClearFilter}>
            <span className={"txt-color-gray txt-size-h8"}>Xóa tìm kiếm</span>
          </Button>
          <Button type="primary"
                  className={"btn--blue pdl20 pdr20"}
                  onClick={onPressSearch}>
            Tìm kiếm
          </Button>

        </div>
      </Form>
    </div>
    <div>
      <Table dataSource={dataSource} columns={columns}
             loading={loading}
             rowKey={(record) => record?.code || ""}
             locale={{
               emptyText: "Không có dữ liệu"
             }}
             pagination={{
               showSizeChanger: false,
               pageSize: 25,
               total: totalPage,
               onChange: onChangePage,
               current: parseInt(page.toString()) || 1
             }}/>
    </div>
  </Content>)
};

export default ListOrder
