import React, {useEffect, useState} from "react";
import {notification, Select, Spin} from "antd";
import {api} from "../../utils/apiServices";
import _ from "lodash";

const {Option} = Select;


export interface SJSelectCustomerProps {
  value: string,
  onChange: (text: string) => void,
  showSearch?: boolean,
  placeholder?: string
}

export interface CustomerProps {
  username: string,
  firstName: string,
  lastName: string,
}

const SJSelectCustomer: React.FC<SJSelectCustomerProps> = ({value, onChange, showSearch, placeholder}) => {
  const [debouncedCallApi] = useState(() => _.debounce(getCustomer, 1000));
  const [customers, setCustomers] = useState<CustomerProps[]>([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    if (value === "") {
      getCustomer(value || "")
    }
  }, [value]);

  async function getCustomer(username: string) {
    setFetching(true)
    try {
      const params = _.pickBy({
        username
      }, _.identity());
      const data = await api.get(process.env.REACT_APP_NAME_SPACE_CUSTOMER + "/customers", {params});
      setCustomers(data.data);
    } catch (e) {
      notification.error({message: "Lấy thông tin khách hàng thất bại!"})
      console.log("err", e)
    }
    setFetching(false)
  }

  function onSearch(value: string) {
    debouncedCallApi(value)
  }

  const newValue = (fetching || value === "") ? undefined : value
  return <Select
    // labelInValue
    allowClear
    value={newValue}
    placeholder={placeholder}
    notFoundContent={fetching ? <Spin size="small"/> : null}
    filterOption={false}
    onSearch={onSearch}
    onChange={onChange}
    style={{width: '100%'}}
    showSearch={showSearch}
  >
    {customers.map((customer) => (
      <Option key={customer.username}
              value={customer.username}>{customer.username + " (" + customer.firstName + " " + customer.lastName + ")"}</Option>
    ))}
  </Select>
};
export default SJSelectCustomer
