import React, {useEffect} from "react";
import {Button, Result} from "antd";
import {PageProps} from "../../app/types";
import {useDispatch} from "react-redux";
import {setPageTitle} from "../../../store/actions/app";

const Page403: React.FC<PageProps> = ({history, location}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle({title: "403"}))
  }, []);
  return <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={<Button type="primary" onClick={() => {
      history.replace("/")
    }}>Back Home</Button>}
  />
};
export default Page403

