import {Menu} from "antd";
import React, {useEffect, useState} from "react";
import {menus} from "./data"
import {connect, useDispatch} from "react-redux";
import {setMenuActive} from "../../store/actions/app";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import Cookies from "js-cookie";
import {isPermission} from "../../utils/helps";

export interface SJMenuProps {
  menu: string
}
export interface TokenProps {
  permissions: any,
}

const SJMenu: React.FC<SJMenuProps> = ({menu}) => {
  const dispatch = useDispatch();
  const [dataMenu, setMenu] = useState<any[]>([])
  useEffect(() => {
    const token = Cookies.get("access_token") || "";
    if(token && token.length > 0){
      const newMenu: any[] = []
      menus.forEach(m => {
        let newSubMenu: any[] = [];
        let subMenu = m.subMenu || [];
        subMenu.forEach(sub => {
          if (isPermission(sub.permissions)) newSubMenu.push(sub)
        })
        m.subMenu = newSubMenu;
        if (isPermission(m.permissions)) newMenu.push(m)
      })
      setMenu(newMenu)
    }
  },[])
  function onSelect(menu: any) {
    dispatch(setMenuActive({menu: menu}))
  }
  return <Menu theme="dark" mode="inline" selectedKeys={[menu]}
               triggerSubMenuAction={"hover"}>
    {dataMenu.map(menu => {
      if (!!menu.subMenu && menu.subMenu.length > 0) {
        return renderSubMenu(menu, onSelect)
      }
      return renderMenuItem(menu, onSelect)
    })}
  </Menu>
};
const renderMenuItem = (menu: any, onSelect: (menu: any) => void) => {
  const defaultPath = menu.path ? menu.path : "/";
  const path = defaultPath.charAt(0) === "/" ? defaultPath : "/" + defaultPath;
  return <Menu.Item key={menu.key} icon={menu.icon ? <menu.icon/> :
    <ExclamationCircleOutlined/>}
                    onClick={() => onSelect(menu.key)}>
    <Link to={path}>{menu.title}</Link>
  </Menu.Item>
};
const renderSubMenu = (menu: any, onSelect: (menu: any) => void) => {
  return <Menu.SubMenu key={menu.key} title={menu.title}
                       icon={menu.icon ? <menu.icon/> :
                         <ExclamationCircleOutlined/>}>
    {menu.subMenu.map((m: any) => renderMenuItem(m, onSelect))}
  </Menu.SubMenu>
};
const mapStateToProps = (state: any) => ({
  menu: state?.app?.menu,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SJMenu);
