import axios, {AxiosRequestConfig} from "axios";
import {handleStatusHttps} from "./helps";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_PUBLIC_URL;
const api = {

  getInstance: () => {
    return axios.create({
      baseURL: BASE_URL,
      timeout: 30000,
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("access_token"),
      }
    });
  },

  get: (url: string, options?: AxiosRequestConfig | undefined) => {
    const defaultOptions = {
      method: "get",
    };
    if (url.charAt(0) === "/") {
      url = url.slice(1, url.length)
    }
    options = Object.assign(defaultOptions, options, {});
    return api.getInstance().get(url, options).then(res => res)
      .catch(err => {
        handleStatusHttps(err?.response?.status || "");
        throw err
      });
  },

  post: (url: string, data: any, options?: AxiosRequestConfig | undefined) => {
    let defaultOptions = {
      method: "post"
    };
    if (url.charAt(0) === "/") {
      url = url.slice(1, url.length)
    }
    options = Object.assign(defaultOptions, options, {});

    return api.getInstance().post(url, data, options)
      .then(res => res)
      .catch(err => {
        handleStatusHttps(err.response.status);
        throw err
      });
  },

  postForm: (url: string, data: any, options?: AxiosRequestConfig | undefined) => {
    let defaultOptions = {
      method: "post",
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    if (url.charAt(0) === "/") {
      url = url.slice(1, url.length)
    }

    options = Object.assign(defaultOptions, options, {});
    return api.getInstance().post(url, data, options).then(res => res)
      .catch(err => {
        handleStatusHttps(err.response.status);
        throw err
      });
  },

  put: (url: string, data: any, options?: AxiosRequestConfig | undefined) => {
    let defaultOptions = {
      method: "put"
    };
    if (url.charAt(0) === "/") {
      url = url.slice(1, url.length)
    }
    options = Object.assign(defaultOptions, options, {});

    return api.getInstance().put(url, data, options).then(res => res)
      .catch(err => {
        handleStatusHttps(err.response.status);
        throw err
      });
  },

  patch: (url: string, data?: any, options?: AxiosRequestConfig | undefined) => {
    let defaultOptions = {
      method: "patch"
    };
    if (url.charAt(0) === "/") {
      url = url.slice(1, url.length)
    }
    options = Object.assign(defaultOptions, options, {});

    return api.getInstance().patch(url, data, options).then(res => res)
      .catch(err => {
        handleStatusHttps(err.response.status);
        throw err
      });

  },

  delete: (url: string, options?: AxiosRequestConfig | undefined) => {
    let defaultOptions = {
      method: "delete"
    };
    if (url.charAt(0) === "/") {
      url = url.slice(1, url.length)
    }
    options = Object.assign(defaultOptions, options, {});

    return api.getInstance().delete(url, options,).then(res => res)
      .catch(err => {
        handleStatusHttps(err.response.status);
        throw err
      });
  }
};

export {api};

