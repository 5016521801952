import React, {useEffect} from "react";
import {Result, Button} from "antd";
import {PageProps} from "../../app/types";
import {useDispatch} from "react-redux";
import {setPageTitle} from "../../../store/actions/app";

const Page404: React.FC<PageProps> = ({history, location}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle({title: "404"}))
  }, []);
  return  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" onClick={() => {
      history.replace("/")
    }}>Back Home</Button>}
  />
};
export default Page404

