import {UserOutlined} from "@ant-design/icons";
import {Popover} from "antd";
import React, {useEffect} from "react";
import Cookies from "js-cookie";
import lodash from "lodash";
import {api} from "../../utils/apiServices";

const href = process.env.REACT_APP_AUTHOURIZATION;

export interface SJUserProps {

}

const SJUserPanel: React.FC<SJUserProps> = () => {
  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      if (lodash.isNil(Cookies.get("access_token"))) {
        window.location.href = href || ""
      }
    }
  }, []);
  function Logout() {
    Cookies.remove("access_token");
    window.location.href = href || ""
  }

  const content = (
    <div>
      <p onClick={Logout}>Logout</p>
    </div>
  );
  return <Popover content={content}>
    <UserOutlined style={{fontSize: '20px'}}/>
  </Popover>
}

export default SJUserPanel;
