import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {setMenuActive, setPageTitle} from "../../store/actions/app";
import {useTranslation} from 'react-i18next';

const Home: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setMenuActive({menu: "menu_home"}));
    dispatch(setPageTitle({title: "Trang chủ"}))
    document.title = "Trang chủ";
  });
  return <div className="App">{t("common:hello")}</div>;
};

export default Home;
