import Home from 'pages/home';
import ListOrder from "../pages/order/list";
import page404 from "../pages/errors/page404";
import Login from "../pages/login";
import DetailOrder from "../pages/order/detail";
import LoginCallBack from "../pages/auth/callback";
import ListDraftOrder from "../pages/draft-orders/list";
import UploadPage from "../pages/upload";
import DetailDraftOrder from "../pages/draft-orders/detail";
import {permissions} from "../utils/permissions";
import Page403 from "../pages/errors/page403";
import Page404 from "../pages/errors/page404";

export const routes = [
  {
    path: '/',
    component: Home,
    permissions: [],
    notAuthorized: true
  },
  {
    path: '/orders',
    routes: [
      {
        path: '/',
        component: ListOrder,
        permissions: [permissions.ORDER_LIST_VIEW]

      },
      {
        path: '/:code',
        component: DetailOrder,
        permissions: [permissions.ORDER_DETAIL_VIEW]
      },
    ],
  },
  {
    path: '/draft-orders',
    routes: [
      {
        path: '/',
        title: 'Danh sách đơn nháp',
        component: ListDraftOrder,
        permissions: [permissions.DRAFT_ORDER_LIST_VIEW]
      },
      {
        path: '/:code',
        component: DetailDraftOrder,
        permissions: [permissions.DRAFT_ORDER_DETAIL_VIEW]
      },
    ]
  },
  {
    path: '/upload',
    routes: [
      {
        path: '/',
        component: UploadPage,
        permissions: [permissions.ORDER_UPLOAD_VIEW]
      },
    ],
  },
  {
    path: '/auth',
    routes: [
      {
        path: '/callback',
        component: LoginCallBack,
        permissions: [],
        notAuthorized: true
      },
    ],
  },
  {
    path: '/login',
    component: Login,
    permissions: [],
    notAuthorized: true
  },
  ,
  {
    path: '/403',
    component: Page403,
    permissions: [],
    notAuthorized: true
  },
  {
    path: '*',
    component: Page404,
    permissions: [],
    notAuthorized: true
  }

];
