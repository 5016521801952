import {Checkbox, Modal, notification, Table, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {api} from "../../../utils/apiServices";
import {formatDateTime} from "../../../utils/helps";

export interface ModalProps {
  isVisible?: boolean | undefined,
  onCancel?: any,
  code: string,
}

export interface LogsProps {
  code?: string,
  data?: any,
  reason?: string,
  id?: string,
  createdAt?: string,
  status?: string,
  orderImport?: string
}

const ModalLog: React.FC<ModalProps> = ({isVisible, onCancel, code}) => {
  const [dataLog, setDataLog] = useState<LogsProps[]>([]);
  const [paginate, setPaginate] = useState({totalPage: 0, page: 0});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isVisible) {
      getData()
    }
  }, [code]);

  async function getData(params?: {status?: string, page?: number}) {
    setLoading(true);
    try {
      const data = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/order-import/files/" + code + "/logs", {
        params: {
          ...params,
          page: params ? params.page ? params.page - 1 : 0 : 0,
          sort: "createdAt:DESC"
        }
      });
      setDataLog(data.data);
      setPaginate({
        totalPage: data.headers["x-total-count"],
        page: data.headers["x-page-number"],
      })
    } catch (e) {
      notification.error({message: "Lỗi lấy dữ liệu!"})
    }
    setLoading(false)
  }

  function onChangePage(page:number) {
    getData({page})
  }

  async function onChangeStatus(checked: boolean, code: string) {
    setLoading(true);
    try {
      const status = checked ? "FIX" : "FAILED";
      const data = await api.patch(process.env.REACT_APP_NAME_SPACE_ORDER + "/order-import/logs/" + code, {status});
      let newDataLog: LogsProps[] = dataLog;
      newDataLog = (newDataLog as LogsProps[] || []).map((log: LogsProps) => log.id === code ? {
        ...log,
        status
      } : log);
      setDataLog(newDataLog);
    } catch (e) {
      notification.error({message: "Thay đổi trạng thái thất bại!"})
    }
    setLoading(false);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: "5%",
      render: (code: string, record: LogsProps) => {
        return <Checkbox
          disabled={!record.reason}
          checked={record.status === "FIX"}
          onChange={(e) => onChangeStatus(e.target.checked, code)}/>
      }
    },

    {
      title: 'Ngày upload',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: "20%",
      render: (text: string) => {
        return <p>{formatDateTime(text)}</p>
      }
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      ellipsis: true,
      render: (data: any) => {
        return <Tooltip placement="topLeft"
                        title={data.toString()}><span>{data.toString()}</span></Tooltip>
      }
    },
    {
      title: 'Lỗi',
      dataIndex: 'reason',
      key: 'reason',
      ellipsis: true,
      render: (reason: string) => {
        return <Tooltip placement="topLeft" title={reason}><span
          style={{width: 100}}>{reason}</span></Tooltip>
      }
    }
  ];
  return <Modal title="Logs"
                visible={isVisible}
                width={1000}
                footer={null}
                onCancel={onCancel}>
    <Table dataSource={dataLog} columns={columns}
           loading={loading}
           rowKey={(record: LogsProps) => record.id || ""}
           locale={{
             emptyText: "Không có dữ liệu"
           }}
           pagination={{
             showSizeChanger: false,
             pageSize: 25,
             total: paginate.totalPage,
             onChange: onChangePage,
             current: parseInt(paginate.page.toString()) + 1 || 1
           }}/>
  </Modal>
};

export default ModalLog
