import React, {useEffect, useState} from "react";
import {Button, Form, Input, Modal, notification, Table, Upload} from 'antd';
import {
  ExclamationCircleOutlined,
  InboxOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import {setMenuActive, setPageTitle} from "../../store/actions/app";
import {useDispatch} from "react-redux";
import {UploadOutlined} from "@ant-design/icons/lib";
import {PageProps} from "../app/types";
import {
  formatDateTime,
  getFilter,
  getPage,
  isCsvFile,
  isPermission
} from "../../utils/helps";
import {api} from "../../utils/apiServices";
import _ from "lodash";
import queryString from "query-string";
import {useTranslation} from "react-i18next";
import ModalLog from "./modal";
import {permissions} from "../../utils/permissions";
import {FormInstance} from "antd/lib/form";

const {Dragger} = Upload;

interface FileUploadProps {
  name?: string,
  status?: any,
  code?: string,
  createdAt?: string,
}

export interface NewStateProps {
  order?: string | undefined,
  sort?: string | undefined,
  page?: string | undefined,
}


const UploadPage: React.FC<PageProps> = (props) => {
    const [form] = Form.useForm();
    const formRef = React.createRef<FormInstance>();
    const [dataSource, setData] = useState([]);
    const [totalPage, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [codeLog, setCodeLogShow] = useState("");
    const [isVisibleModalLog, setVisibleModalLog] = useState(false);
    const [isModalSourceVisible, setModalSourceVisible] = useState(false);
    const search = queryString.parse(props?.location?.search || "");
    const defaultState = {
      order: "",
      sort: "",
      page: "",
    };
    const [state, setState] = useState({
      ...defaultState,
      ...search
    });
    const {order, sort, page} = state;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(setPageTitle({title: "Upload thông tin đơn hàng"}));
      dispatch(setMenuActive({menu: "upload"}));
      document.title = "Upload thông tin đơn hàng";
      const newSearch = queryString.parse(props?.location?.search || "");
      if(isPermission([permissions.ORDER_UPLOAD_VIEW])) {
        getData({...defaultState, ...newSearch})
      }
    }, [props.location]);
    const columns = [
      {
        title: 'Tên file',
        dataIndex: 'name',
        key: 'name',

      },
      {
        title: 'Ngày upload',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => {
          return <p>{formatDateTime(text)}</p>
        }
      },
      {
        title: "",
        key: "action,",
        render: (text: string, record: FileUploadProps) => {
          return <Button onClick={() => showLog(record.code || "")}>Chi
            tiết</Button>
        }
      }

    ];

    async function getData(newState?: NewStateProps) {
      setLoading(true);
      try {
        const params = _.pickBy({
          ...newState,
          sort: sort || "createdAt:DESC",
          page: newState ? parseInt(newState.page || "1") - 1 : 0,
        }, _.identity());
        const data = await api.get(process.env.REACT_APP_NAME_SPACE_ORDER + "/order-import/files", {params});
        setData(data.data);
        setState({
          ...defaultState,
          ...params,
          page: getPage(params.page),
        });

        setTotal(data?.headers["x-total-count"]);
      } catch (e) {
        console.log("e", e)
      }
      setLoading(false)
    }


    const beforeUpload = () => false;

    const onFinish = async () => {
     setModalSourceVisible(true)

    };
    const onUpload = async () => {
      const values = formRef.current!.getFieldsValue();
      setLoading(true);
      try {
        const data = new FormData();
        data.append('file', values?.dragger[0]);
        data.append('source', values?.source);
        await api.postForm(process.env.REACT_APP_NAME_SPACE_ORDER + "/orders/import", data);
        getData();
        form.setFieldsValue({source : "", dragger : []})
        notification.success({message: "Upload file thành công!"})
      } catch (e) {
        notification.error({message: "Upload file thất bại!"})
      }
      setLoading(false);
      setModalSourceVisible(false)

    }

    const onRemoveFile = () => {
      form.resetFields()
    };
    const normFile = (e: { fileList: any; file: any }) => {
      if (Array.isArray(e)) {
        return e;
      }
      if (e.fileList.length === 0) {
        return []
      }
      return e && [e.file];
    };

    function onChangePage(page: number) {
      onSearch({...state, page: page.toString()})
    }

    function onSearch(newState?: NewStateProps) {
      let filter = _.pickBy({
        ...newState,
      }, _.identity());
      let pathname = props.location.pathname ? props.location.pathname : props.history.location.pathname;
      props.history.push(pathname + '?' + queryString.stringify(getFilter(filter)));
    }

    async function showLog(code: string) {
      setVisibleModalLog(true);
      setCodeLogShow(code)
    }

    const onCancelModalSource = () => {
      setModalSourceVisible(false)
      form.setFieldsValue({source : ""})

    }

    return <div>
      {
        isPermission([permissions.ORDER_UPDATE_UPLOAD_FILE]) &&
        <Form onFinish={onFinish} form={form} ref={formRef}>
          <Form.Item
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{
              required: true,
              message: "Bạn chưa chọn file!"
            }, {
              validator: async (rule, value) => {
                if (value) {
                  if (value.length && !isCsvFile(value[0].name)) {
                    throw new Error("Sai định dạng file!")
                  }
                }
              }
            }]}>
            <Dragger height={300}
                     accept={".csv"}
                     multiple={false}
                     beforeUpload={beforeUpload}
                     onRemove={onRemoveFile}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined/>
              </p>
              <p className="ant-upload-text">Nhấp hoặc kéo tệp vào khu vực này để
                tải lên</p>
              <p className="ant-upload-hint">
                Chỉ hỗ trợ một file đuôi .csv.
              </p>
            </Dragger>

          </Form.Item>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
              display: "flex",
              marginBottom: 50
            }}>
            {/*<Upload >*/}
            <Button icon={loading? <LoadingOutlined /> : <UploadOutlined/>} htmlType="submit" disabled={loading}>Upload</Button>
            {/*</Upload>*/}
            <Modal title="Nguồn tạo đơn"
                   visible={isModalSourceVisible}
                   okButtonProps={{loading, disabled: loading}}
                   cancelButtonProps={{loading, disabled: loading}}
                   onOk={onUpload}
                   onCancel={onCancelModalSource}>
              <Form.Item name="source">
                <Input  placeholder={"Nhập nguồn tạo đơn"}/>
              </Form.Item>
            </Modal>
          </div>
        </Form>
      }
      {
        isPermission([permissions.ORDER_UPLOAD_VIEW]) &&
        <Table dataSource={dataSource} columns={columns}
               loading={loading}
               rowKey={(record) => record.code || ""}
               locale={{
                 emptyText: "Không có dữ liệu"
               }}
               pagination={{
                 showSizeChanger: false,
                 pageSize: 25,
                 total: totalPage,
                 onChange: onChangePage,
                 current: parseInt(page.toString()) || 1
               }}/>
      }
      <ModalLog isVisible={isVisibleModalLog}
                code={codeLog}
                onCancel={() => setVisibleModalLog(false)}/>

    </div>
  }
;

export default UploadPage
