import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {TokenProps} from "../SJMenu";
import { useHistory } from "react-router-dom";
import {isPermission} from "../../utils/helps";

export interface SJAuthorizedProps {
  authorized: boolean,
  permissions?: any
}

const SJAuthorized: React.FC<SJAuthorizedProps> = ({children, authorized,permissions }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    const token = Cookies.get("access_token") || "";
    if(token && token.length > 0){
      if (authorized && !isPermission(permissions)) {
          history.replace("/403")
      }
    }
    setLoading(false)
  }, [authorized]);
  if(loading) {
    return <></>
  }
  return <>{children}</>
};

export default SJAuthorized
