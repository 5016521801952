import {
  GitlabOutlined,
  OrderedListOutlined,
  UploadOutlined
} from "@ant-design/icons/lib";
import {permissions} from "../../utils/permissions";

export const menus = [
  {
    key: "menu_home",
    title: "Home",
    permissions: ["home"]
  },
  {
  key: "menu_order",
  title: "Order",
  icon: GitlabOutlined,
  permissions: [
    permissions.DRAFT_ORDER_LIST_VIEW,
    // permissions.DRAFT_ORDER_UPDATE_CONFIRM_PAYMENT,
    permissions.ORDER_LIST_VIEW,
    // permissions.ORDER_UPDATE_CHANGE_STATUS,
    // permissions.ORDER_UPDATE_CONFIRM,
  ],
  subMenu: [
    {
      title: "DS đơn hàng",
      icon: OrderedListOutlined,
      permissions: [
        // permissions.ORDER_UPDATE_CONFIRM,
        permissions.ORDER_LIST_VIEW,
        // permissions.ORDER_UPDATE_CHANGE_STATUS
      ],
      key: "list_order",
      path: "orders"
    },
    {
      title: "DS đơn nháp",
      icon: OrderedListOutlined,
      permissions: [
        permissions.DRAFT_ORDER_LIST_VIEW,
        // permissions.DRAFT_ORDER_UPDATE_CONFIRM_PAYMENT
      ],
      key: "list_draft_order",
      path: "draft-orders"
    }
  ]
}, {
  key: "menu_upload",
  title: "Tải file",
  icon: UploadOutlined,
  permissions: [
    permissions.ORDER_UPLOAD_VIEW,
    // permissions.ORDER_UPDATE_UPLOAD_FILE
  ],
  path: "/upload"
  // subMenu: [
  //   {
  //     title: "Tải file",
  //     icon: CloudUploadOutlined,
  //     permission: ["upload"],
  //     key: "upload",
  //     path: "upload"
  //   }
  // ]
}];
