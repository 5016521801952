export const permissions = {
  ORDER_LIST_VIEW:"order:list:view",	                                          //Xem danh sách đơn hàng
  ORDER_DETAIL_VIEW : "order:detail:view",                                      //Xem chi tiết đơn hàng
  ORDER_UPDATE_CONFIRM : "order:update:confirm",	                              //Xác nhận đơn hàng
  ORDER_UPDATE_CHANGE_STATUS : "order:update:change_status",                    //Thay đổi trạng thái đơn hàng
  DRAFT_ORDER_LIST_VIEW: "draft_order:list:view",	                              //Xem danh sách đơn hàng nháp
  DRAFT_ORDER_DETAIL_VIEW : "draft_order:detail:view",                          //Xem chi tiết đơn hàng nháp
  DRAFT_ORDER_UPDATE_CONFIRM_PAYMENT : "draft_order:update:confirm_payment",	  //Xác nhận đã thanh toán đơn nháp
  ORDER_UPLOAD_VIEW : "order_upload:view",	                                    //Truy cập trang upload file
  ORDER_UPDATE_UPLOAD_FILE :"order_upload:upload_file"	                        //Upload file đơn hàng
}
