import {applyMiddleware, createStore, Store} from 'redux';
import {PersistConfig, persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';
import {rootReducer} from 'store/reducers';
import {rootSaga} from "./saga";

const reduxPersistConfig: PersistConfig<any> = {
  key: 'application',
  storage: storage,
  stateReconciler: autoMergeLevel2
};
const saga = createSagaMiddleware();


const pReducer = persistReducer(reduxPersistConfig, rootReducer);

export const store: Store = createStore(pReducer, applyMiddleware(saga));
saga.run(rootSaga);
export const persistor = persistStore(store);
