import React from "react";
import {Layout} from 'antd';
import SJUserPanel from "../SJUserPanel";
import SJPageTitle from "../SJPageTitle";
import {connect} from "react-redux";
import SJLanguage from "../SJLanguage";

const {Header} = Layout;

export interface SJHeaderProps {
  title: string
}

const SJHeader: React.FC<SJHeaderProps> = ({title}) => {
  return (
    <Header
      className="header bg-color-white mgbt20 ant-layout-header">
      <div
        className={"dpl-flex justify-content-between"}>
        <div className="header__left dpl-flex pdt10">
          <SJPageTitle description={"hello"} title={title}/>
        </div>
        <div className={"header__right dpl-flex "}>
          <div
            className={"drop-down-user cursor-pointer dpl-flex"}
            style={{
              height: 49,
              flexDirection: "column",
              justifyContent: "center",
              marginRight: 40
            }}>
            <SJLanguage/>
          </div>
          <div
            className={"drop-down-user cursor-pointer dpl-flex"}
            style={{
              height: 49,
              flexDirection: "column",
              justifyContent: "center"
            }}>
            <SJUserPanel/>
          </div>
        </div>
      </div>
    </Header>
  )
};


const mapStateToProps = (state: any) => ({
  title: state?.app?.title,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SJHeader);
