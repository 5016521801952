import moment from "moment";
import lodash from "lodash";
// import queryString from 'query-string';
import {History, Location, LocationState} from "history";
import {notification} from "antd";
import Cookies from "js-cookie";
import {TokenProps} from "../components/SJMenu";
import jwt_decode from "jwt-decode";

export interface historyPushProps {
  history: History<LocationState>;
  location: Location<LocationState>;
}

export const formatRoutes = (routes: any[], currentPath?: string) => {
  let newRoutes: any[] = [];
  routes.forEach(route => {
    route.path = (currentPath || "") + route.path;
    if (route.routes) {
      newRoutes = formatRoutes(route.routes, route.path).concat(newRoutes)
    } else {
      newRoutes.push(route)
    }
  });
  return newRoutes;
};
export const formatDateTime = (datetime: string) => {
  return moment(datetime).format("DD/MM/YYYY HH:mm")
};
export const formatCurrency = (value: string, dec_point = ',') => {
  if (lodash.isNaN(value) || !value) {
    return 0;
  }
  value = value.toString();
  value = lodash.round(parseFloat(value)).toString()
  return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, dec_point);
};
export const isEmptyObject = (object: object | null | undefined) => {
  return lodash.isObject(object) && !Object.keys(lodash.omitBy(object, value => lodash.isNil(value))).length;
};

export const getFilter = (filter: Partial<any>) => {
  filter = lodash.omitBy(filter, value => value === '' || isEmptyObject(value));
  return filter
};
export const getPage = (page?: number | string | undefined) => {
  if (!page) {
    return "1"
  }
  if (typeof page === "string") {
    return (parseInt(page) + 1).toString()
  }
  return (page + 1).toString()
};
export const getFileTypeExtension = (fileName: string) => {
  return fileName.substring(fileName.lastIndexOf('.'))
};
export const getStringToArrayExtension = (accept: string) => {
  return lodash.split(lodash.replace(accept, " ", ""), ",")
};
export const checkFileExtension = (fileName: string, accept: string | any[]) => {
  if (lodash.isNil(fileName) || lodash.isUndefined(fileName)) {
    return false
  }
  fileName = fileName.toString();

  if (Array.isArray(accept)) {
    return accept.includes(getFileTypeExtension(fileName));
  }

  return getStringToArrayExtension(accept).includes(getFileTypeExtension(fileName))
};
export const isCsvFile = (fileName: any) => checkFileExtension(fileName, ['.csv']);
export const getProfile = () => {
  const data_profile = Cookies.get("profile") || "";
  if(!data_profile || data_profile.length === 0){
    return false;
  }
  return JSON.parse(data_profile)
}
export const isPermission = (curPermissions : any) => {
  const profile = getProfile();
  const token = Cookies.get("access_token") || "";
  if(!profile && (!token || token.length <= 0)) {
    return false
  }
  const data: TokenProps = profile || jwt_decode(token || "") || {}
  const allPermissions: any[] = data?.permissions || [];
  return allPermissions.some(permission => (curPermissions || []).indexOf(permission) >= 0);
}
export function handleStatusHttps(status: number) {
  switch (status) {
    case 401:
      Cookies.remove("access_token");
      Cookies.remove("profile");
      notification.error({message: "Bạn chưa đăng nhập"});
      window.location.href = process.env.REACT_APP_AUTHOURIZATION || "";
      break;

    case 403:
      notification.error({message: "Bạn không có quyền thực hiện thao tác này!"});
      break;
    // case 404:
    //   notification.error({message: "Api không tồn tại!"});
    //   break;
    case 500:
      notification.error({message: "Lỗi máy chủ nội bộ!"});
      break;
    default :
      // notification.error({message: "Lỗi!"});
      break;
  }
}
