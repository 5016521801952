import {Layout} from 'antd';
import React, {useState} from 'react';
import SJHeader from "../components/SJHeader";
import SJMenu from "../components/SJMenu";
import SJAuthorized from "../components/SJAuthorized";

const {Header, Sider, Content} = Layout;

export interface BasicLayoutProps {
  children: any;
  authorized: boolean;
  permissions?: any
}

const BasicLayout: React.FC<BasicLayoutProps> = (props) => {
  const [collapsed] = useState(false);
  const {children} = props;
  return (
    <div className={'height100'}>
      <Layout className={'height100'}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="nav height100 position-fix"
        >
          <div className="a-logo">
            <img className="logo-collapse"
                 style={{width: 100}}
                 src={process.env.REACT_APP_PRODUCT_LOGO}
                 alt=""/>
          </div>
          <SJMenu/>
        </Sider>
        <Layout className="site-layout pdl200">
          <Header className="site-layout-background" style={{padding: 0}}>
            <SJHeader/>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 20,
            }}
          >
            <SJAuthorized authorized={props.authorized} permissions={props.permissions}>
              {children}
            </SJAuthorized>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default BasicLayout;
