import {SET_MENU, SET_PAGE_TITLE} from '../../models/app.modal';
import {APP_CONSTANTS} from 'store/constants';
import {AppActionTypes} from "./type";

export function setPageTitle(payload: SET_PAGE_TITLE): AppActionTypes {
  return {
    type: APP_CONSTANTS.SET_PAGE_TITLE,
    payload
  };
}

export function setMenuActive(payload: SET_MENU): AppActionTypes {
  return {
    type: APP_CONSTANTS.SET_MENU,
    payload
  };
}
