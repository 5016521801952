import React from "react";

export interface SJPageTitleProps {
  description: string,
  title: string
}

const SJPageTitle: React.FC<SJPageTitleProps> = ({description, title}) => {
  return (
    <React.Fragment>
      <h2
        className="txt-size-h2 txt-color-black robotomedium line-height134">{title || process.env.REACT_APP_PRODUCT_NAME}</h2>
      {/*{description}*/}
    </React.Fragment>

  )
};

export default SJPageTitle
